import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../App';

function MessageWidget({ currentNumber, autoReplyStatus, onMessageSent }) {
  const [messageInput, setMessageInput] = useState('');
  const [update, setUpdate] = useState(false);
  const {serverIP} = useAuth();

  useEffect(()=>{
    setUpdate(p=>!p);
  },[currentNumber, autoReplyStatus, onMessageSent])

  const sendMessage = () => {
    if (currentNumber && messageInput.trim() !== '') {
      axios.post(serverIP + '/send_message', {
        number: currentNumber,
        message: messageInput
      })
        .then(() => {
          setMessageInput('');
          onMessageSent();
        })
        .catch(error => console.error(error));
    }
  };

  const isDisabled = !autoReplyStatus[currentNumber];

  return (
    <div className='flex justify-center gap-2 w-full items-center mb-4 align-middle h-auto'>
      <input
        type="text"
        placeholder="הקלד הודעה..."
        className="block w-[90%] h-12 mainInput"
        disabled={isDisabled}
        value={messageInput}
        onChange={e => setMessageInput(e.target.value)}
      />
      <div className='w-[12%] flex justify-center'>
        <button
          onClick={sendMessage}
          className='w-full p-2 align-middle mainButton'
          disabled={isDisabled || !currentNumber}
          style={isDisabled|| !currentNumber ? {background:'grey', cursor:'default'} : {fontWeight:'bold'}}
        >
          שלח
        </button>
      </div>
    </div>
  );
}

export default MessageWidget;
