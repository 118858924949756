import Cookies from 'js-cookie';

export function setCookie(name, value, expirationMinutes = 20) {
  const expirationDate = new Date();
  expirationDate.setMinutes(expirationDate.getMinutes() + expirationMinutes);
  Cookies.set(name, value, { expires: expirationDate });
}

export function getCookie(name) {
  return Cookies.get(name);
}

export function removeCookie(name) {
  Cookies.remove(name);
}