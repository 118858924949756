import React, { useState } from 'react';
import Contacts from './Contacts';
import Chat from './Chat';
import Insights from './Insights';


function Home() {
  const [currentNumber, setCurrentNumber] = useState(undefined);
  const [autoReplyStatus, setAutoReplyStatus] = useState({});

  const handleSelectNumber = (number) => {
    setCurrentNumber(number);
  };

  const updateAutoReplyStatus = (status) => {
    setAutoReplyStatus(status);
  };

  return (
    <div>
      <div className='layout-wrapper'>
        <Contacts
          onSelectNumber={handleSelectNumber}
          autoReplyStatus={autoReplyStatus}
          setAutoReplyStatus={updateAutoReplyStatus}
        />
        <Chat currentNumber={currentNumber} 
          autoReplyStatus={autoReplyStatus} />
        <Insights currentNumber={currentNumber} />
      </div>
    </div>
  );
}

export default Home;
