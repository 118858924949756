import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { useAuth, useThemeMode } from '../App';
import { messageBubble } from './Chat';
import { Icon } from '@iconify/react/dist/iconify.js';
import Skeleton from 'react-loading-skeleton';
import { useBoolean } from '../utils/hooks/use-boolean';
import { NumberItem } from './Contacts';

function Reports() {
  const [contacts, setContacts] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [dateRange, setDateRange] = useState('day');
  const [currentNumber, setCurrentNumber] = useState(null);
  const showReports =useBoolean(false);
  const stopSkeleton =useBoolean(false);
  const {mode} = useThemeMode();
  const [messages, setMessages] = useState([]);
  const [conversationInsights, setConversationInsights] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const {serverIP} = useAuth();
 

  useEffect(() => {
    fetchContacts();
    fetchTotalCost();
    if (!stopSkeleton.value){
      setTimeout(()=>{
        stopSkeleton.onTrue()
      }, 12*1e3)
    }
  }, [dateRange]);

  const fetchContacts = () => {
    axios.post(serverIP+'/get_conversations', { range_type: dateRange })
      .then(response => {
        setContacts(response.data);
      })
      .catch(error => console.error(error));
  };

  const fetchTotalCost = () => {
    axios.get(serverIP +'/get_total_cost')
      .then(response => {
        setTotalCost(response.data);
      })
      .catch(error => console.error(error));
  };

  const loadChat = (number) => {
    setCurrentNumber(number);
    axios.get(serverIP +`/messages/${number}`)
      .then(response => {
        setMessages(response.data?.messages);
        setConversationInsights(response.data);
      })
      .catch(error => console.error(error));
  };

  const handleNumberClick = (number) => {
    if (number === currentNumber){
      setCurrentNumber(null);
      setMessages([]);
      setConversationInsights(null);
      setTotalCost(null);
      return;
    }
    loadChat(number)}

  const Skeletons = <>
    <Skeleton borderRadius={4} baseColor='grey' width='85%' className='rounded-main' highlightColor='lightgrey' height={12} />
    <Skeleton borderRadius={4} baseColor='grey' width='60%' className='rounded-main' highlightColor='lightgrey' height={12} />
    <Skeleton borderRadius={4} baseColor='grey' width='70%' className='rounded-main' highlightColor='lightgrey' height={12} />
    <Skeleton borderRadius={4} baseColor='grey' width='50%' className='rounded-main' highlightColor='lightgrey' height={12} />
    <Skeleton borderRadius={4} baseColor='grey' width='40%' className='rounded-main' highlightColor='lightgrey' height={12} />
  </>

const ContactSkeleton = <div className='flex h-12'>
          <div className='flex-[4] mb-1'>
            <Skeleton count={1} borderRadius={4} baseColor='grey' width='85%' className='rounded-main' highlightColor='lightgrey' height={13} />
            <Skeleton count={1} borderRadius={4} baseColor='grey' width='60%' className='rounded-main' highlightColor='lightgrey' height={10} />
            <Skeleton count={1} borderRadius={4} baseColor='grey' width='40%' className='rounded-main' highlightColor='lightgrey' height={8} />
          </div>
          <div className='flex-[1]'>
            <Skeleton  baseColor='grey' width={28} height={30} className='!rounded-main' highlightColor='lightgrey'  />
          </div>
          </div>

  const filteredContacts = contacts.filter(number =>
    String(number.number).includes(searchInput) ||
    (number?.last_time?.includes(searchInput)) || 
    (number.name?.toLowerCase()?.includes(searchInput?.toLocaleLowerCase()))
  );

  const layout = 
  ( <motion.div 
      initial={{opacity:0, y:30}}
      animate={{opacity:1, y:0}}
      exit={{opacity:0, y:30}}
      transition={{duration: 0.8}}
      className='layout-wrapper'>
    {/* עמודת בחירת זמן ומספרים */}
    <div className='layout-right scroll z-20'>
    
      <label htmlFor="dateRange">בחר טווח זמן:</label>
      <select
        id="dateRange"
        className="block text-center w-[90%] my-2 h-12 mainInput"
        value={dateRange}
        
        onChange={e => setDateRange(e.target.value)}
      >
        <option value="day">היום האחרון</option>
        <option value="week">השבוע האחרון</option>
        <option value="month">החודש האחרון</option>
      </select>
      <input
        type="text"
        className="block w-[90%] mb-2 h-12 mainInput"
        placeholder="חיפוש..."
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
      />
        <h3 className='mb-2 font-bold'>מספרים</h3>
      <ul>
        {filteredContacts.length === 0 ? (
          <li>אין שיחות להצגה בטווח זמן זה.</li>
        ) :
        !filteredContacts.length && !stopSkeleton.value ?
      <>
          {Array(10).fill(1).map((item, index)=>(
            <li key={index} className={`w-full h-20 block px-2 dark:shadow-gray-500/20 cursor-pointer m-2 border-b-[1px] pb-2 border-gray-500`}>
                  {ContactSkeleton}
            </li>
          ))}
      </>
      :
         (
          filteredContacts.map(number => (
            <NumberItem 
              number={number}
              refreshItem={fetchContacts}
              currentNumber={{current: currentNumber}}
              isSelected={currentNumber ===  number.number}
              onSelectNumber={handleNumberClick}
              key={number.number}
              showNumOfMsg
              hideButton  />
          ))
        )}
      </ul>
    </div>

    {/* עמודת צ'אט */}
    <div className='layout-center relative scroll chat-background'>
      <div className='flex flex-col h-full mb-8'>
        {messages.length === 0 ? (
          <p>בחר מספר כדי להציג את השיחה.</p>
        ) : (
          messages.map((message, index) => (
            <div key={index} style={{justifyContent:message.sender}} className='flex w-full'>
              <div {...messageBubble(message?.sender || 'right', mode)}
                key={index}
                
              >
                {message.message}
                <div className='text-sm text-gray-600 dark:text-gray-400'>{message.time}</div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>

    {/* עמודת תובנות */}
    <div className='layout-left scroll'>
      <h3 className=' mb-2 font-bold'>תובנות</h3>
      <div className='opacity-85'>
        {totalCost ? (
          <div >
            <h4 className='font-[600]'>עלות כוללת של כל השיחות:</h4>
            <p>סה"כ טוקנים שהתקבלו מהמשתמשים (כולל פרומפטים): {totalCost.total_input_tokens}</p>
            <p>סה"כ טוקנים שנשלחו למשתמשים: {totalCost.total_output_tokens}</p>
            <p>עלות הודעות נכנסות: ${totalCost.input_cost.toFixed(2)}</p>
            <p>עלות הודעות יוצאות: ${totalCost.output_cost.toFixed(2)}</p>
            <p className='font-bold !opacity-100 !mt-2'>עלות כוללת: ${totalCost.total_cost.toFixed(2)}</p>
            <hr className='my-2' />
            <h4 className='font-[600]'>פרטים על הפרומפטים:</h4>
            <p>כמות הטוקנים בפרומפט: {totalCost.prompt_tokens_per_message}</p>
            <p>כמות ההודעות שהתקבלו מהלקוחות: {totalCost.total_number_of_user_messages}</p>
            <p>כמות הטוקנים של הפרומפט שנשלחו: {totalCost.total_prompt_tokens}</p>
            <p className='font-bold !opacity-100 !mt-2'>עלות הפרומפט לבד: ${totalCost.prompt_cost.toFixed(2)}</p>
          </div>
        ):
        (Skeletons)}
        <hr className='my-2' />
        {conversationInsights ? (
          <div>
            <p className='font-[600]'>תובנות עבור מספר: {currentNumber}</p>
            <p>סה"כ טוקנים שהתקבלו מהמשתמש: {conversationInsights.input_tokens}</p>
            <p>סה"כ טוקנים שנשלחו למשתמש: {conversationInsights.output_tokens}</p>
            <p>עלות הודעות נכנסות: ${conversationInsights.input_cost.toFixed(2)}</p>
            <p>עלות הודעות יוצאות: ${conversationInsights.output_cost.toFixed(2)}</p>
            <p className='font-bold !opacity-100 !mt-2'>עלות כוללת: ${conversationInsights.total_cost.toFixed(2)}</p>
            <button
            className='p-2 my-4 mainButton'
              onClick={() => {
                setCurrentNumber(null);
                setMessages([]);
                setConversationInsights(null);
              }}
            >
              חזרה למבט כולל
            </button>
          </div>
        ) : (
          <p>בחר מספר כדי להציג תובנות על השיחה.</p>
        )}
      </div>
    </div>
  </motion.div>)

  return (
    <div className='w-full min-h-[80vh]'>  
        {!showReports.value  ? 
        <>
        <div className='h-20'/>
        <div className='w-full p-4 flex justify-center'>
          <button
            className='h-fit mainButton'
            onClick={showReports.onToggle}
          >
            הצג היסטוריה
          </button>
        </div>
        </>
        : layout}
    </div>
  );
}

export default Reports;
