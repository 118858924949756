import { Icon } from "@iconify/react/dist/iconify.js";
import { useBoolean } from "../../utils/hooks/use-boolean";
import { useThemeMode } from "../../App";


export default function ThemeModePicker({gap=15}){
    const {mode, changeMode} = useThemeMode();
    const isDark = Boolean(mode === 'dark');

    return(
        <div style={{gap:`${gap}px`}} className="flex ">
            <div 
            style={{opacity:isDark ? 0.5:1}}
            onClick={changeMode}
             className="buttonWrapper">
            <Icon icon="ix:light-dark" />
            </div>
            <div 
            style={{opacity:isDark ? 1:0.5}}
            onClick={changeMode}
             className="buttonWrapper">
            <Icon icon="circum:dark" />
            </div>
        </div>
    )
}