import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import MessageWidget from './MessageWidget';
import { useAuth, useThemeMode } from '../App';
import './assets/chatBackgrounds.css';
import { getCookie, setCookie } from '../utils/hooks/use-cookie';

const whatsappColors = {
  light: {
    right: '#FFFFFF', // Light green
    textColor: '#000', // Black
    left: '#DCF8C6' // Light greenish for sender
  },
  dark: {
    left: 'rgb(0, 92, 75)', // Dark green
    textColor: 'rgb(233, 237, 239)', // White
    right: '#202c33' // Slightly brighter dark green for sender
  }
};

export const messageBubble  = (sender, mode)=> ({
  className: `p-4 m-4 w-fit relative max-w-[75%] max-sm:max-w[90%] text-[16px]/4 shadow-lg dark:shadow-gray-400/15 text-start `,
  style: {
    background:  whatsappColors[mode][sender],
    color: whatsappColors[mode]?.textColor,
    transition: 'all 0.3s ease-in-out',
    borderRadius: sender === 'right' ? '25px 25px 25px 0px': '0px 25px 25px 25px'
  }})


function Chat({ currentNumber, autoReplyStatus }) {
  const [messages, setMessages] = useState([]);
  const [update, setUpdate] = useState(false);
  const {serverIP} = useAuth();
  const {mode} = useThemeMode();
  const chatWindowRef = useRef(null);
  const randomBG = useRef(getCookie('chatBG') || null);

  useEffect(() => {
    if (currentNumber) {
      loadChat();
    }
    else{
      setMessages([]);
    }
    if (!randomBG.current) {
      randomBG.current = `chat-background${Math.ceil(Math.random()*6)}`;
      setCookie('chatBG',randomBG.current, 20);
      setUpdate(p=>!p);
    }
  }, [currentNumber]);

  const loadChat = () => {
    axios.get(serverIP+ `/messages/${currentNumber}`)
      .then(response => {
        setMessages(response.data.messages);
        // ניתן לעדכן גם תובנות או עלויות אם צריך
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentNumber) {
        loadChat();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [currentNumber]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={`layout-center relative scroll ${randomBG.current}`}>
      {/* <div className='absolute top-0 bg-repeat left-0 h-full w-full bg-fixed chat-background' /> */}
    
        <div className='flex flex-col mb-8 h-full' ref={chatWindowRef}>
          {messages.length === 0 ? (
            <div {...messageBubble('left', mode)} >אין הודעות להצגה.</div>
          ) : (
            messages.map((message, index) => (
              <div key={index} className='w-full h-full'>
              <div style={{justifyContent:message.sender}} className='flex w-full'>
                <div key={index} {...messageBubble(message?.sender || 'right', mode)}>
                  {message.message}
                  <div className='dark:text-gray-400 text-gray-500 text-right mt-[5px] text-[0.75rem]'>{message.time}</div>
                </div>
              </div>
              {index === (messages.length -1) && <div className='py-10 opacity-0'/> }
              </div>
            ))
          )}
        </div>
        <div className='w-[100%] flex justify-center sticky top-[90%]'>
          <MessageWidget
            currentNumber={currentNumber}
            autoReplyStatus={autoReplyStatus}
            onMessageSent={loadChat}
          />
        </div>
    </div>
  );
}

export default Chat;
