import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Reports from './components/Reports';
import Settings from './components/Settings';
import axios from 'axios';
import Header from './components/Header';
import './styles.css';
import Home from './components/Home';
import Footer from './components/Footer';
import { getCookie, setCookie } from './utils/hooks/use-cookie';
import { createTheme, ThemeProvider, useColorScheme } from '@mui/material/styles';
import theme, { getTheme } from './utils/theme/themeGPT';

const AuthContext = createContext();
const ThemeContext = createContext();

// פונקציה שאוספת מידע אם המשתמש מחובר
export function useAuth() {
  return useContext(AuthContext);
}

export function useThemeMode() {
  const {mode, changeMode} = useContext(ThemeContext);

  return {mode, changeMode};
}

// פונקציה שאוספת את ההעדפה של המשתמש (רקע כהה  או בהיר)
function getUserColorScheme() {
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    return "dark"; // User prefers dark mode
  } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: light)").matches) {
    return "light"; // User prefers light mode
  }
  return undefined; // Default or no specific preference
}

// יצירת ערכת נושא לשימוש בכלים של MUI


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [collectionName, setCollectionName] = useState(undefined);
  const [userName, setUserName] = useState(undefined);
  // const {setMode} = useColorScheme();
  const [mode, setColorMode] = useState(getUserColorScheme() || 'light');
  const serverIP = useRef('');
  // בדיקת אימות כאשר האפליקציה נטענת
  useEffect(() => {;
    if (process.env.NODE_ENV === 'development'){
        const isAuth = getCookie('auth_token');
        if (isAuth){
          setIsAuthenticated(true);
          setUserName(isAuth);
        }
    }
    axios.get(serverIP.current + '/check_auth')
      .then(response => {
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
        }
      })
      .catch(error => {
        setIsAuthenticated(false);
      });
  }, []);

  // בדיקת מצב תצוגה כאשר האפליקציה נטענת
  useEffect(()=>{
    const storageMode = getCookie('themeMode');
    if (storageMode && storageMode === 'dark'){
      document?.body?.classList.add('dark');
      setCookie('themeMode', 'dark');
      setColorMode('dark');
      // setMode('dark');
    }
    else if (storageMode && storageMode === 'light'){
      document.body.classList.remove('dark');
      setCookie('themeMode', 'light');
      setColorMode('light');
      // setMode('light');
    }
    else {
      if (mode === 'dark'){
        document.body.classList.add('dark');
        setCookie('themeMode', 'dark');
      }
      else{
        document.body.classList.add('light');
        setCookie('themeMode', 'light');
      }
    }
  },[])

  const changeMode = ()=>{
    if (mode === 'dark'){
      const body = document.body;
      body.classList.remove('dark');
      setCookie('themeMode', 'light');
      setColorMode('light');
      // setMode('light');
    }
    else if (mode === 'light'){
      const body = document.body;
      body.classList.add('dark');
      setCookie('themeMode', 'dark');
      setColorMode('dark');
      // setMode('dark');
    }
    else{  //  Default value in case no Storage and no prefered colorScheme
      setColorMode('light');
      // setMode('light');
    }
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, collectionName, setCollectionName ,serverIP: serverIP.current}}>
      <ThemeContext.Provider value={{mode, changeMode}}>
        <ThemeProvider theme={getTheme(mode)}>
          <Router>
            <div className='min-h-screen relative'>
            <div className="z-[0] fixed inset-0 bg-gradient-to-br from-main from-15% via-main/80 to-main/60 filter blur-[300px] ml-[-8vw] mt-[-30vh] w-[55vh] h-[55vh] rounded-[50%] "/>
            {isAuthenticated && <Header userName={userName}  />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              {isAuthenticated ? (
                <>
                  <Route path="/home" element={<Home />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/settings" element={<Settings />} />
                </>
              ) : (
                <Route path="*" element={<Navigate to="/login" />} />
              )}
            </Routes>
              <Footer />
              </div>
          </Router>
          </ThemeProvider>
        </ThemeContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
