import ThemeModePicker from "./assets/ThemeMode";


function Footer ({}){

    return(
        <footer className="m-2 mt-0 relative z-50">
            <hr className="mb-2 border-gray-300 sm:mx-auto dark:border-gray-700 lg:mb-2" />
            <div className="w-full max-w-screen-xl mx-auto px-4 pt-2 pb-1 md:pt-2">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a href="https://taskomatic.vercel.app/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                        <img src={`${process.env.PUBLIC_URL}assets/logo.png`} className="h-12 aspect-auto" alt="taskomatic Logo" />
                        {/* <span className="self-center text-2xl font-semibold whitespace-nowrap">Taskomatic</span> */}
                    </a>
                    <ul className="flex relative flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                        <li>
                            <a href="https://taskomatic.vercel.app/" className="hover:underline me-4 md:me-6">דף הבית</a>
                        </li>
                        <li>
                            <a href="#" className="hover:underline me-4 md:me-6">מדיניות פרטיות</a>
                        </li>
                        {/* <li>
                            <a href="#" className="hover:underline me-4 md:me-6">Licensing</a>
                        </li>
                        <li>
                            <a href="#" className="hover:underline">Contact</a>
                        </li> */}
                    </ul>

                        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://taskomatic.vercel.app/" className="hover:underline">Taskomatic</a> All Rights Reserved</span>
                        
                   
                </div>
                {/* <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" /> */}
            </div>
        </footer>
    )
}

export default Footer;
