import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../App';
import { getCookie, removeCookie } from '../utils/hooks/use-cookie';
import ThemeModePicker from './assets/ThemeMode';
import { Tooltip } from '@mui/material';

function Header({ userName }) {
  const navigate = useNavigate();
  const { setIsAuthenticated, serverIP , collectionName} = useAuth();
  const location = useLocation();
  // Get the current pathname
  const currentPath = location.pathname;
  const collectionNameFinal = collectionName || getCookie('collection_name');
  // Map route paths to page names
  const pageNames = {
      "/home": "ראשי",
      "/login": "",
      "/reports": "דוחות ותובנות",
      "/settings": "הגדרות מערכת" ,
  };

  const title = pageNames[currentPath] || '';

  const handleLogout = () => {
    axios.post(serverIP+ '/logout')
      .then(() => {
        setIsAuthenticated(false);
        removeCookie('auth_token');
        navigate('/login');
      })
      .catch(error => console.error(error));
  };

  return (
    <>
    <div className='h-8 hidden max-sm:block'></div>
    <div className='fixed z-50 top-0 bg-white/10 dark:bg-black/10 backdrop-blur-lg bg-gradient-to-r from-gray-200/10 to-gray-100/20 dark:bg-gradient-to-r dark:from-black/40 dark:to-slate-950/30 px-4 pb-2 pt-4 w-full flex justify-between items-center border-b-slate-400/20 border-b-[0.7px] rounded-b-3xl shadow-lg '>
      <div className='flex gap-4 items-end'>
      {userName && <h3 style={{fontFamily:'Alef'}} className='text-lg font-bold mx-4 max-sm:hidden'>היי {userName}</h3>}
        {collectionNameFinal && 
        <Tooltip arrow title={'שם האוסף'}>
          <div className='rounded-full cursor-default content-center mx-4 px-3 py-2 text-center font-bold text-sm bg-gradient-to-br from-main via-teal-500/80 to-main/40'>
            {collectionNameFinal}
          </div>
        </Tooltip>}
      </div>
      <div className='flex gap-8 mx-4 items-end relative max-sm:hidden'>
       
        <button className={`headerButton ${currentPath === '/home' ? 'bg-slate-300/40 !opacity-100':''}`} onClick={() => navigate('/home')}>ראשי</button>
        <button className={`headerButton ${currentPath === '/reports' ? 'bg-slate-300/40 !opacity-100':''}`} onClick={() => navigate('/reports')}>דוחות</button>
        <button className={`headerButton ${currentPath === '/settings' ? 'bg-slate-300/40 !opacity-100':''}`} onClick={() => navigate('/settings')}>הגדרות מערכת</button>
        <button className='headerButton'  onClick={handleLogout}>התנתק</button>
        <ThemeModePicker />
      </div>
      <div className='w-full justify-center gap-8 relative hidden max-sm:flex'>
        <button className={`headerButton ${currentPath === '/reports' ? 'bg-slate-300/40 !opacity-100':''}`} onClick={() => navigate('/reports')}>דוחות</button>
        <button className={`headerButton ${currentPath === '/' ? 'bg-slate-300/40 !opacity-100':''}`} onClick={() => navigate('/')}>ראשי</button>
        <button className={`headerButton ${currentPath === '/settings' ? 'bg-slate-300/40 !opacity-100':''}`} onClick={() => navigate('/settings')}>מערכת</button>
        <button className='headerButton'  onClick={handleLogout}>התנתק</button>
      </div>
    </div>
    </>
  );
}

export default Header;
