import { blueGrey, grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const lightMode = {
  palette: {
    mode: "light",
    primary: {
      main: "#4a90e2", // Vibrant blue
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f5a623", // Bright orange
      contrastText: "#000000",
    },
    background: {
      paper: "#ffffff", // Pure white for cards and papers
    },
    text: {
      primary: "#333333", // Dark grey for text
      secondary: "#555555", // Lighter grey for secondary text
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: { fontWeight: 700, fontSize: "2.5rem" },
    h2: { fontWeight: 600, fontSize: "2rem" },
    body1: { fontWeight: 400, fontSize: "1rem" },
    button: { textTransform: "none", fontWeight: 600 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Rounded corners
          padding: "10px 20px",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          ":hover": {
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          padding: "16px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        },
      },
    },
  },
};

const darkMode = {
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9", // Soft blue
      contrastText: "#000000",
    },
    secondary: {
      main: "#ffb74d", // Warm amber
      contrastText: "#000000",
    },
    background: {
      default: "#121212", // Dark grey
      paper: "#000", // Slightly lighter dark grey
    },
    text: {
      primary: "#ffffff", // White text
      secondary: "#cccccc", // Greyish white for secondary text
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: { fontWeight: 700, fontSize: "2.5rem", color: "#ffffff" },
    h2: { fontWeight: 600, fontSize: "2rem", color: "#cccccc" },
    body1: { fontWeight: 400, fontSize: "1rem" },
    button: { textTransform: "none", fontWeight: 600 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "10px 20px",
          boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.1)",
          ":hover": {
            boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.2)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          padding: "16px",
          boxShadow: "0px 2px 8px rgba(255, 255, 255, 0.1)",
        },
      },
    },
  },
};

export const getTheme = (mode) =>
  createTheme(mode === "light" ? lightMode : darkMode);
