import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../App';
import { motion } from 'framer-motion';
import {useBoolean} from '../utils/hooks/use-boolean';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Controller, useForm } from 'react-hook-form';
import { Icon } from '@iconify/react/dist/iconify.js';
import { getCookie, setCookie } from '../utils/hooks/use-cookie';
import Loader from './assets/loader';
import ThemeModePicker from './assets/ThemeMode';


function Login() {
  const [error, setError] = useState('');
  const isLoading = useBoolean(true);
  const isFetching = useBoolean(false);
  const isRedirecting = useBoolean(false);
  const showText = useBoolean(false);
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, serverIP,  setCollectionName } = useAuth();
  const {    
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }} = useForm({mode:'onChange'})

   useEffect(()=>{
    if (isLoading.value){
      setTimeout(()=>{
        isLoading.onFalse();
      }, .8*1e3)
    }
  },[])

  useEffect(()=>{
    if (isAuthenticated){
      isRedirecting.onTrue()
      setTimeout(()=>{   
        navigate('/home');
      },1*1e3);
    }
  })

  const handleForm = (data) => {
    isFetching.onTrue();
    axios.post(serverIP+'/login', data)
      .then((res) => {
        setCollectionName(res.data?.collection_name || undefined)
        setTimeout(()=>{
        setIsAuthenticated(true); // עדכון מצב ההתחברות
        if (process.env.NODE_ENV === 'development'){
           setCookie('auth_token', data.username,10) //   **  For development  use only!  **
           if (res.data?.collection_name){
             setCookie('collection_name',res.data?.collection_name,10) //   **  For development  use only!  **
           }
        }
        navigate('/');
        isFetching.onFalse();
        },0.8*1e3)
      })
      .catch((error) => {
       setTimeout(()=>{
        if (error.response && error.response.status === 401) {
          setError('שם משתמש או סיסמה שגויים');
        } else {
          setError('שגיאה בשרת. אנא נסה שוב מאוחר יותר.');
        }
        isFetching.onFalse();
       },0.5*1e3)
      });
  };



  const skeleton = <Skeleton borderRadius={8} baseColor='grey' className='rounded-main' highlightColor='lightgrey' height={45} />;
  const loginForm =    
  <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form onSubmit={handleSubmit(handleForm)} className="space-y-6">
      <div>
        <label htmlFor="email" className="block text-sm/6 font-medium">
          שם משתמש
        </label>
        <div className="mt-2">
        { isLoading.value ?
        skeleton:  
        <Controller
            id="username"
            control={control}
            defaultValue=''
            name="username"
            type="text"
            rules={{
              required:'נא להזין שם מתשמש', 
              // pattern: {
              //     value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              //     message: 'כתובת אימייל לא תקינה',
              //   }
          }}
            render={({field})=>
            <>
            <input
            style={{direction:'ltr'}}
            {...field}
            autoComplete="email"
            className="block w-full h-12 rounded-lg border-0 dark:bg-white/5 bg-black/5 py-1.5 shadow-sm hover:shadow-md ring-1 ring-inset  dark:ring-white/10 ring-black/10 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm/6"
          />
          <div className='text-xs mt-2 text-red-400 opacity-80'>
          {errors?.username?.message}
          </div>
          </>
          } 

          />}
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between">
          <label htmlFor="password" className="block text-sm/6 font-medium">
          סיסמה
          </label>
          <div className="text-sm">
            <a href="#" className="font-semibold text-gray-400 hover:opacity-80">
              שכחתם סיסמה?
            </a>
          </div>
        </div>
        <div className="mt-2">
        { isLoading.value ?
        skeleton: 
        <Controller
            id="password"
            name="password"
            defaultValue=''
            rules={{
              required:
            'נא להזין סיסמה'
            }}
            control={control}
            
            render={({field})=>
              <>
              <div className='relative'>
              <input
              {...field}
            style={{direction:'ltr'}}
            type={showText.value ? "text" : "password"}
            autoComplete="current-password"
            className="block w-full rounded-lg h-12 border-0 dark:bg-white/5 bg-black/5 py-1.5 shadow-sm hover:shadow-md ring-1 ring-inset dark:ring-white/10 ring-black/10 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm/6"
          />
            <div className='absolute top-1/3 cursor-pointer right-4'>
            {showText.value ? <Icon width={20} onClick={showText.onFalse} icon="mdi-light:eye" /> : <Icon onClick={showText.onTrue} width={20} icon="mdi-light:eye-off" />}
            </div>
          </div>
          <div className='text-xs mt-2 text-red-400 opacity-80'>
          
          {errors?.password?.message}
          </div>
          </>}
          />}
        </div>
      </div>
      <div >
        <button
          type="submit"
          disabled={isLoading.value || isFetching.value || errors.username || errors.password}
          className="flex items-center mt-12 w-full justify-center duration-300 mainButton !p-3 text-lg/6 font-semibold shadow-sm shadow-gray-800 hover:shadow-main  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main"
        >
          התחברות
          {isFetching.value && <Icon className='animate-spin mx-2' icon="tabler:loader-2" />}
        </button>
        {error && <div className='text-red-500 text-center mt-4'>{error}</div>}
      </div>
    </form>
    <p className="mt-10 text-center text-sm/6 text-gray-400">
      אין לכם חשבון? {' '}
      <a href="https://taskomatic.vercel.app/" className="font-semibold text-main/80 hover:text-indigo-300">
        לחצו כאן
      </a>
    </p>
  </div>
  const loader = <div className='w-full h-50vh flex justify-center'>
                     <Loader />
                </div>

 
  return (
    <motion.div 
    animate={{opacity:1, y:0}}
    initial={{opacity:0, y:30}}
    transition={{ duration: 0.8 }} 
    className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
  {!isRedirecting.value &&  <div className='w-full flex justify-center'>
      <ThemeModePicker gap={30} />
    </div>}
      <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight">כניסה לאיזור האישי</h2>
    </div>
    {isRedirecting.value ? loader : loginForm}
  </motion.div>
  );
}

export default Login;
