import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../App';
import Skeleton from 'react-loading-skeleton';
import { useBoolean } from '../utils/hooks/use-boolean';

function Insights({ currentNumber }) {
  const [conversationInsights, setConversationInsights] = useState(undefined);
  const [totalCost, setTotalCost] = useState(null);
  const isLoadingInsights = useBoolean(false);
  const stopSkeleton = useBoolean(false);
  const {serverIP} = useAuth();

  useEffect(() => {
    fetchTotalCost();
    if (!stopSkeleton.value){
      setTimeout(()=>{
        stopSkeleton.onTrue();
      }, 12*1e3)
    }
  }, []);

  useEffect(() => {
    if (currentNumber) {
      loadConversationInsights();
    }else{
      setConversationInsights(undefined);
    }
  }, [currentNumber]);

  const fetchTotalCost = () => {
    axios.get(serverIP +'/get_total_cost')
      .then(response => {
        setTotalCost(response.data);
      })
      .catch(error => {
        setTotalCost(null)
        console.error(error)});
  };


  const loadConversationInsights = () => {
    isLoadingInsights.onTrue();
    axios.get(serverIP + `/messages/${currentNumber}`)
      .then(response => {
        setConversationInsights(response.data);
        isLoadingInsights.onFalse();
      })
      .catch(error => {
        setConversationInsights(undefined)
        isLoadingInsights.onFalse();
        console.error(error)});
  };

  const Skeletons = <>
        <Skeleton borderRadius={4} baseColor='gray' width='85%' className='rounded-main' highlightColor='lightgrey' height={12} />
        <Skeleton borderRadius={4} baseColor='gray' width='60%' className='rounded-main' highlightColor='lightgrey' height={12} />
        <Skeleton borderRadius={4} baseColor='gray' width='70%' className='rounded-main' highlightColor='lightgrey' height={12} />
        <Skeleton borderRadius={4} baseColor='gray' width='50%' className='rounded-main' highlightColor='lightgrey' height={12} />
        <div className='w-full h-4' />
        <Skeleton borderRadius={4} baseColor='gray' width='40%' className='rounded-main' highlightColor='lightgrey' height={12} />
      </>

  return (
    <div className='layout-left scroll'>
      <h3 className='font-bold !opacity-100 !mt-2 mb-2'>תובנות</h3>
      <div className='opacity-85'>
        {totalCost ? (
          <div>
            <h4>עלות כוללת של כל השיחות:</h4>
            <p>סה"כ טוקנים שהתקבלו מהמשתמשים: {totalCost.total_input_tokens}</p>
            <p>סה"כ טוקנים שנשלחו למשתמשים: {totalCost.total_output_tokens}</p>
            <p>עלות הודעות נכנסות: ${totalCost.input_cost.toFixed(2)}</p>
            <p>עלות הודעות יוצאות: ${totalCost.output_cost.toFixed(2)}</p>
            <p className='font-bold !opacity-100 !mt-2'>עלות כוללת: ${totalCost.total_cost.toFixed(2)}</p>
          </div>
        ):
        !stopSkeleton.value && Skeletons}
        <hr className='my-2' />

        {isLoadingInsights.value ? 
        Skeletons:
          conversationInsights ? (
          <div>
            <p ><strong style={{direction:'ltr'}}>תובנות עבור מספר: {currentNumber?.slice(0, 4) + '-' + currentNumber?.slice(4)}</strong></p>
            <p>סה"כ טוקנים שהתקבלו מהמשתמש: {conversationInsights.input_tokens}</p>
            <p>סה"כ טוקנים שנשלחו למשתמש: {conversationInsights.output_tokens}</p>
            <p>עלות הודעות נכנסות: ${conversationInsights.input_cost.toFixed(2)}</p>
            <p>עלות הודעות יוצאות: ${conversationInsights.output_cost.toFixed(2)}</p>
            <p className='font-bold !opacity-100 !mt-2'>עלות כוללת: ${conversationInsights.total_cost.toFixed(2)}</p>
          </div>
        ) : (
          <p>בחר מספר כדי להציג תובנות על השיחה.</p>
        )}
      </div>
    </div>
  );
}

export default Insights;
